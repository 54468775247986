import React, {ChangeEvent, FC} from "react";
import style from "./MobileApplicationSettings.module.css";
import StatusController from "../../features/StatusController/StatusController";
import InputWLabel from "../Common/Input/InputWLabel/InputWLabel";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {updatePopupTimes} from "../../features/mobile/mobileSlice";


const MobileApplicationSettings: FC = (): JSX.Element => {
    const mobileOptions = useAppSelector((state) => state.mobile);
    const {ios_times, android_times} = mobileOptions;
    console.log("SDSDSD", mobileOptions)

    const dispatch = useAppDispatch();
    const updateTimes = (
        name: "ios_times" | "android_times",
        e: ChangeEvent<HTMLInputElement>
    ) => {
        dispatch(updatePopupTimes({name, value: e.target.value}));
    };
    return (
        <div className={style.container}>
            <h2 className={style.header}>Mobile popup settings</h2>
            <div className={style.wrapHeader}>
                <div className={style.wrapSide}>
                    <StatusController id={"iOSPopupStatus"} name={"iOS"}/>
                    <div className={style.inputWrapper}>
                        <label>How many times show</label>
                        <InputWLabel id={'ios_times'} value={ios_times ?  ios_times.toString() : "1"}
                                     updateValue={(e) => updateTimes("ios_times", e)}/>
                    </div>
                </div>
                <div className={style.wrapSide}>
                    <StatusController id={"androidPopupStatus"} name={"android"}/>
                    <div className={style.inputWrapper}>
                        <label>How many times show</label>
                        <InputWLabel id={'android_times'} value={android_times ? android_times.toString() : '1'}
                                     updateValue={(e) => updateTimes("android_times", e)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileApplicationSettings;