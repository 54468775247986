import { ChangeEvent, forwardRef, LegacyRef } from "react";
import Input from "..";
import style from "./style.module.css";

interface InputWLabelProps {
  id: string;
  value: string;
  updateValue: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  placeHolder?: string;
  noBM?: boolean;
  // ref?: HTMLInputElement;
}

const InputWLabel = forwardRef<HTMLInputElement, InputWLabelProps>(
  (props: InputWLabelProps, ref) => {
    return (
      <div className={`${style.container} ${props.noBM ? style.noBM : ""}`}>
        <label className={style.label} htmlFor={props.id}>
          {props.name}
        </label>
        <Input {...props} ref={ref} />
      </div>
    );
  }
);

export default InputWLabel;
