import style from "./VideoStatus.module.css";
import { desktopData, mobileData, sourceData } from "../../data/videoData";
import VideoForm from "../Common/VideoForm/VideoForm";
import CheckBoxWLabel from "../Common/CheckBox/CheckBoxWLabel/CheckBoxWLabel";
import VideoLeftSide from "../../features/video/VideoLeftSide";
import StatusController from "../../features/StatusController/StatusController";
import { useState } from "react";
import FoldedContainer from "../FoldedContainer/FoldedContainer";

const VideoStatus = (): JSX.Element => {
  return (
    <FoldedContainer rightIcon="270">
      <>
        <StatusController id="videoStatus" name="שידור video" />

        <div className={`${style.Detailcontainer}  `}>
          <div className={style.right}>
            <div className={style.topRight}>
              <div className={style.flex1}>
                <div>
                  <VideoForm {...desktopData} checkBoxName="desktopStatus" />
                </div>
                <div className={style.topBottomRight}>
                  <VideoForm {...mobileData} checkBoxName="mobileStatus" />
                </div>
              </div>
              <div className={style.flex1}>
                <VideoForm {...sourceData} />
              </div>
            </div>
            <div className={style.bottomRight}>
              <CheckBoxWLabel name="נגן וידאו עם פרסומת" id="advertising" />
              <CheckBoxWLabel name="autoplay" id="autoPlay" />
            </div>
          </div>
          <div className={style.left}>
            <VideoLeftSide />
          </div>
        </div>
      </>
    </FoldedContainer>
  );
};

export default VideoStatus;
