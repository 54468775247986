import React, { useEffect, useState } from "react";
import HeaderStatus from "./components/HeaderStatus/HeaderStatus";
import style from "./App.module.css";
import HomePageStatus from "./components/HomePageStatus/HomePageStatus";
import SurveyStatus from "./features/Survey/Survey";
import VideoStatus from "./components/VideoStatus/VideoStatus";
import ActionMenu from "./components/ActionMenu/ActionMenu";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { fetchProdStatus } from "./features/StatusController/StatusControllerSlice";
import MagazineStatus from "./components/MagzineStatus/MagazineStatus";
import Preview from "./features/PreviewPage/Preview";
import MobileApplicationSettings from "./components/MobileApplicationSettings/MobileApplicationSettings";

function App() {
  const dispatch = useAppDispatch();
  const isPreview = useAppSelector((state) => state.preview);
  useEffect(() => {
    dispatch(fetchProdStatus());
  }, []);

  return (
    <div className={style.container}>
      {isPreview && <Preview />}
      <ActionMenu />
      <HomePageStatus />
      <VideoStatus />
      {/*<HeaderStatus />*/}
      {/*<SurveyStatus />*/}
      <MagazineStatus />
      <MobileApplicationSettings/>
    </div>
  );
}

export default App;
