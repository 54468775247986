import React, {FC, useEffect, useRef, useState} from 'react';
// @ts-ignore
import videojs, {VideoJsPlayerOptions} from 'video.js';
import 'video.js/dist/video-js.css';

interface IProps {
    sourceLink: string,
}

const VideoPlayer: FC<IProps> = (props) => {
    const {sourceLink} = props;

    const videoNode = useRef(null);
    const [player, setPlayer] = useState(null);

    const sources = [
        {
            src: sourceLink,
            type: "application/x-mpegURL"
        },
    ];

    useEffect(() => {
        if (videoNode.current) {
            const _player = videojs(videoNode.current, {
                autoplay: true,
                controls: false,
                responsive: true,
                fluid: true,
                muted: true,
                sources
            });
            setPlayer(_player);
            return () => {
                if (player !== null) {
                    (player as any).dispose();
                }
            };
        }
    }, []);

    return (
        <div data-vjs-player>
            <video ref={videoNode} className="video-js"></video>
        </div>
    );
};

export default VideoPlayer;
