import { ChangeEvent, forwardRef, LegacyRef } from "react";
import style from "./style.module.css";

interface InputProps {
  id: string;
  name?: string;
  value: string;
  updateValue: (e: ChangeEvent<HTMLInputElement>) => void;
  placeHolder?: string;

  // ref?: HTMLInputElement;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ value, updateValue, id, placeHolder, name }, ref) => {
    return (
      <input
        ref={ref}
        className={style.input}
        type="text"
        value={value}
        onChange={updateValue}
        id={id}
        placeholder={placeHolder}
        name={name}
      />
    );
  }
);
export default Input;
