import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import StatusController from "../../features/StatusController/StatusController";
import { setMazgaineOption } from "../../features/StatusController/StatusControllerSlice";
import { TMagzineOption } from "../../interface";
import Radio from "../Common/Radio";
import FoldedContainer from "../FoldedContainer/FoldedContainer";
import style from "./MagazineStatus.module.css";
const MagazineStatus = (): JSX.Element => {
  const state = useAppSelector((state) => state.detailStatus.magazineOption);
  const dispatch = useAppDispatch();

  const handleMagazineVal = (value: TMagzineOption) => {
    dispatch(setMazgaineOption(value));
  };

  return (
    <FoldedContainer>
      <>
        <StatusController id="magazineStatus" name="מגזין" />
        <ul className={`${style.ul} `}>
          <li onClick={() => handleMagazineVal("default")}>
            <Radio
              homePage
              orValue={"default"}
              value={state}
              name={"magazineOption"}
            />
            <img
              src={
                state === "default"
                  ? "/assets/magazine-on-3.webp"
                  : "/assets/magazine-off-3.webp"
              }
              alt="default"
              height="auto"
              width="200px"
            />
            <p className={style.p}>רגיל</p>
          </li>
          <li onClick={() => handleMagazineVal("big")}>
            <Radio
              homePage
              orValue={"big"}
              value={state}
              name={"magazineStatus"}
            />
            <img
              src={
                state === "big"
                  ? "/assets/magazine-on-1.webp"
                  : "/assets/magazine-off-1.webp"
              }
              alt="big"
              height="auto"
              width="200px"
            />
            <p className={style.p}>מיוחד</p>
          </li>
          <li onClick={() => handleMagazineVal("small")}>
            <Radio
              homePage
              orValue={"small"}
              value={state}
              name={"magazineStatus"}
            />
            <img
              src={
                state === "small"
                  ? "/assets/magazine-on-2.webp"
                  : "/assets/magazine-off-2.webp"
              }
              alt="small"
              height="auto"
              width="200px"
            />
            <p className={style.p}>חגיגי</p>
          </li>
        </ul>
      </>
    </FoldedContainer>
  );
};

export default MagazineStatus;
