import { updateProdData } from "../../api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchProdStatus } from "../../features/StatusController/StatusControllerSlice";
import style from "./style.module.css";
import Loader from "../Common/Loader/Loader";
import { useState } from "react";
import { togglePreview } from "../../features/PreviewPage/previewSlice";

const TIME_LIVE_STATUS_MESSAGE = 4000;

const ActionMenu = (): JSX.Element => {
  const { counter, header, survey, videoOption, detailStatus, mobile } = useAppSelector(
    (state) => state
  );
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const dispatch = useAppDispatch();
  const bodyProd = {
    ...detailStatus,
    id: 1,
    survey: { ...survey, id: 1 },
    video: { ...videoOption, id: 1 },
    mobile: {...mobile, id: 1},
    header: {
      ...header,
      id: 1,
      ...counter,
      counterStatus: detailStatus.counterStatus,
    },
  };

  const bodyPreview = {
    ...detailStatus,
    id: 2,
    survey: { ...survey, id: 2 },
    video: { ...videoOption, id: 2 },
    mobile: {...mobile, id: 2},
    header: {
      ...header,
      id: 2,
      ...counter,
      counterStatus: detailStatus.counterStatus,
    },
  };

  const statusMessageHandle = (message: string) => {
    setStatusMessage(message);
    setTimeout(() => {
      setStatusMessage("");
    }, TIME_LIVE_STATUS_MESSAGE);
  };

  const handlePreview = async () => {
    try {
      setLoading(true);
      const res = await updateProdData(bodyPreview);
      if (res) {
        console.log("Successfully updated");
        statusMessageHandle("!Successfully updated");
      }
      dispatch(togglePreview(true));
    } catch (err) {
      console.log(err);
      statusMessageHandle("!An error occurred while updating");
    } finally {
      setLoading(false);
    }
  };
  const handleSave = async () => {
    console.log(detailStatus);
    try {
      setLoading(true);
      const res = await updateProdData(bodyProd);
      if (res) {
        console.log("Successfully updated");
        statusMessageHandle("!Successfully updated");
      }
    } catch (err) {
      console.log(err);
      statusMessageHandle("!An error occurred while updating");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(fetchProdStatus());
  };

  return (
    <div className={style.container}>
      <h3>הגדרות עמוד ראשי</h3>
      {loading && <Loader />}
      {!loading && statusMessage && (
        <span className={style.statusMessage}>{statusMessage}</span>
      )}
      <div className={style.wrapLeft}>
        <button
          className={`${style.preview} ${style.button} ${
            loading ? style.disabled : ""
          }`}
          onClick={handlePreview}
          disabled={loading}
        >
          תצוגה מקדימה
        </button>
        <button
          className={`${style.save} ${style.button} ${
            loading ? style.disabled : ""
          }`}
          onClick={handleSave}
          disabled={loading}
        >
          פרסם
        </button>
        <button
          className={`${style.cancel} ${style.button} ${style.button} ${
            loading ? style.disabled : ""
          }`}
          onClick={handleCancel}
          disabled={loading}
        >
          בטל
        </button>
      </div>
    </div>
  );
};

export default ActionMenu;
