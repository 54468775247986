import {ChangeEvent, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {updateVideoString} from "./videoSlice";
import InputWLabel from "../../components/Common/Input/InputWLabel/InputWLabel";
import style from "./style.module.css";
import 'video.js/dist/video-js.css';
import VideoPlayer from "../../components/VideoPlayer";
import Loader from "../../components/Common/Loader/Loader";

const VideoLeftSide = () => {
    const videoOptions = useAppSelector((state) => state.videoOption);
    const {desktopFirstItem: firstItem, desktopSecondItem: secondItem, sourceLink, source, editorId} = videoOptions;

    const dispatch = useAppDispatch();

    const updateString = (
        name: "desktopFirstItem" | "desktopSecondItem" | "sourceLink" | "editorId",
        e: ChangeEvent<HTMLInputElement>
    ) => {
        dispatch(updateVideoString({name, value: e.target.value}));
    };

    const videoTagRef = useRef<HTMLDivElement>(null);
    const [isVideoLoading, setIsVideoLoading] = useState(false);

    useEffect(() => {
        setIsVideoLoading(true);
        setTimeout(() => {
            setIsVideoLoading(false)
        }, 0);
    }, [source])

    return (
        <>
            <div className={style.topLeft}>
                <p className={style.topLeftHeader}>תצוגה מקדימה</p>
                {source
                    ? (
                        <>
                            {isVideoLoading && <Loader/>}
                            {!isVideoLoading && <div className={style.videoPreview} ref={videoTagRef}>
                                <VideoPlayer sourceLink={source}/>
                            </div>}
                        </>
                    )
                    : (
                        <img
                            alt="videoDefault"
                            title="videoDefault"
                            className={style.previewImg}
                            src="/assets/videoDefault.webp"
                        />
                    )
                }
                <InputWLabel
                    name="link"
                    id={"editorLink"}
                    value={sourceLink || ""}
                    updateValue={(e) => updateString("sourceLink", e)}
                    placeHolder="הקלד לינק כאן"
                />
            </div>
            <div className={style.bottomLeft}>
                <p className={`${style.topLeftHeader} ${style.borderBot}`}>
                    איטמים דסקטופ
                </p>

                <InputWLabel
                    name="item#"
                    id={"firstItem"}
                    value={firstItem || ""}
                    updateValue={(e) => updateString("desktopFirstItem", e)}
                    placeHolder="הכנס כאן מספר איטם"
                />
                <InputWLabel
                    name="item#"
                    id={"secondItem"}
                    value={secondItem || ""}
                    updateValue={(e) => updateString("desktopSecondItem", e)}
                    placeHolder="הכנס כאן מספר איטם"
                />


                <InputWLabel
                    name="editor id"
                    id={"editorId"}
                    value={editorId || ""}
                    updateValue={(e) => updateString("editorId", e)}
                    placeHolder="החלטות עורך"
                />
            </div>
        </>
    );
};

export default VideoLeftSide;
