import CheckBox from "..";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { toggleData } from "../../../../features/video/videoSlice";
import style from "./style.module.css";

interface CheckBoxWLabelProps {
  name: string;
  id: "advertising" | "autoPlay";
}

const CheckBoxWLabel = ({ name, id }: CheckBoxWLabelProps): JSX.Element => {
  const currentValue = useAppSelector((state) => state.videoOption[id]);
  const dispatch = useAppDispatch();
  const updateValue = () => {
    dispatch(toggleData(id));
  };
  return (
    <div className={style.container}>
      <CheckBox value={currentValue} id={id} updateValue={updateValue} />
      <label htmlFor={id} className={style.header}>
        {name}
      </label>
    </div>
  );
};

export default CheckBoxWLabel;
