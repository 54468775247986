import { useState } from "react";
import style from "./style.module.css";

interface FoldedContainerProps {
  children: JSX.Element;
  rightIcon?: string;
  topIcon?: string;
  maxFoldedHeight?: string;
}

const FoldedContainer = ({
  children,
  rightIcon,
  topIcon,
  maxFoldedHeight,
}: FoldedContainerProps): JSX.Element => {
  const [folded, setFolded] = useState(false);
  const handleCollapse = () => {
    setFolded((pre) => !pre);
  };

  return (
    <div
      className={`${style.container} ${folded && style.folded}`}
      style={{
        maxHeight: `${!folded ? "700px" : `${maxFoldedHeight || "90"}px`}`,
      }}
    >
      {children}
      <div
        style={{
          position: "absolute",
          left: "15px",
          top: `${topIcon || "35"}px`,
        }}
        onClick={handleCollapse}
      >
        <img
          src={folded ? "/assets/down.svg" : "/assets/up.svg"}
          alt={folded ? "down" : "up"}
        />
      </div>
    </div>
  );
};
export default FoldedContainer;
