import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import Loader from "../../components/Common/Loader/Loader";
import style from "./Preview.module.css";
import { togglePreview } from "./previewSlice";

const Preview = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isMobileView, setIsMobileView] = useState(false);
  // const [iFrameLoading, setIframeLoading] = useState(true);

  // const iFrameRef = useRef<HTMLIFrameElement>(null);

  // useEffect(() => {
  //   if (iFrameRef.current)
  //     iFrameRef.current.onload = () => {
  //       setIframeLoading(false);
  //     };
  // }, [iFrameRef]);

  const toggleView = () => {
    setIsMobileView((pre) => !pre);
  };
  const handleExit = () => {
    dispatch(togglePreview(false));
  };

  return (
    <div className={style.container}>
      {/* {iFrameLoading && (
        <div>
          <Loader />
        </div>
      )} */}
      <iframe
        // ref={iFrameRef}
        className={`${style.iframe} ${isMobileView ? style.mobile : ""}`}
        src="https://demo-new.walla.co.il/?preview=1&no-ads=1"
        title="homepage preveiew"
        // style={{ display: iFrameLoading ? "none" : "" }}
      ></iframe>

      <div className={style.wrapButtons}>
        <button className={style.exit} onClick={handleExit}>
          X
        </button>
        <button className={style.pre} onClick={toggleView}>
          מובייל/דסקטופ
        </button>
      </div>
    </div>
  );
};

export default Preview;
