import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toggleDetail } from "./StatusControllerSlice";
import { TStatusFlag } from "../../interface";
import style from "./style.module.scss";

interface StatusControllerProps {
  id: TStatusFlag;
  name: string;
}

const StatusController = ({ id, name }: StatusControllerProps): JSX.Element => {
  const flag = useAppSelector((state) => state.detailStatus[id]);
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const updateValue = () => dispatch(toggleDetail(id));

  return (
    <div className={style.container}>
      <div
        className={`${style.toggle} ${flag ? style.on : style.off}`}
        onClick={() => inputRef.current?.click()}
      >
        <input
          id={id}
          ref={inputRef}
          className="d-none"
          type="checkbox"
          checked={flag}
          onChange={updateValue}
        />
        <span>OFF</span>
        <span>On</span>
      </div>
      <label className={style.label} htmlFor={id}>
        {name}
      </label>
    </div>
  );
};

export default StatusController;
